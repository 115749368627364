async function send_data(send_par,callback)
{
   
    send_par=JSON.stringify(send_par);
    const cu_url='https://meta.anyidata.com/main.php';
    await fetch(cu_url,{
            method:'POST',
            headers:{
                'content-type': 'application/json',
                'Accept-Language':'zh-CN'
            },
            body:send_par
        })
        .then(          
          res=>res.json()
          )
        .then(data=>{              
          
          callback(data)
        })
        .finally(); 
        
};


export default send_data